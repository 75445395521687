import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom'; // Import Link

import logo from '../Img/logo.png';

function BasicExample() {
  return (

    <div className='navigate'>

      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand as={Link} to="/" style={{marginLeft:'-20px'}}>
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>

              <NavDropdown title="About" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/About">About Hospital</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">About Doctor</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Treatment" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/">Kidney Opration</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">Bladder Stones</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">Prostate</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">Urinary infection</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">Narrow urinary tract</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/">Dialysis</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/Facility">Facility</Nav.Link>

              <Nav.Link as={Link} to="/Gallery">Gallery</Nav.Link>

              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

              <div className='appoint'>
                <Nav.Link   as={Link} to="/Appointment" style={{ color: 'black' }}>Appointment</Nav.Link>
              </div>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

export default BasicExample;
