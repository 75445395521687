import React from 'react'
import Footer from '../Components/Footer'

const Facility = () => {
    return (
        <>

            <div className='facility'>

                <p>* Percutaneous Nephrolithotomy (PCNL) for kidney stone removal using endoscopy method.</p>
                <p>* Ureterorenoscopy (URSL) for the operation of stones in the ureter of the kidney using endoscopy method.</p>
                <p>* Transurethral Resection of the Prostate (TURP) for prostate surgery using endoscopy method.</p>
                <p>* Endoscopic procedures for the removal of lumps in the kidneys, bladder, and other related areas.</p>
                <p>* Optical Internal Urethrotomy (OIU) for the endoscopic treatment of strictures in the urethra.</p>
                <p>* Treatment for all types of urinary problems, genital and testicular issues, and sexual health concerns.</p>
                <p>* Management of urinary issues such as frequent urination, weak stream, nocturia, etc.</p>
                <p>* Treatment of urinary problems in women.</p>


                <p>* दूरबीन विधि द्वारा गुर्दे की पथरी का आपरेशन (PCNL)</p>
                <p>* दूरबीन विधि द्वारा गुर्दे की नली में पथरी का आपरेशन (URSL)</p>
                <p>* दूरबीन विधि द्वारा प्रोस्टेट का आपरेशन (TURP)</p>
                <p>* गुर्दे में गाँठ, मूत्राशय में गाँठ आदि का दूरबीन विधि द्वारा आपरेशन</p>
                <p>* पेशाब की नली में सिकुड़न का दूरबीन विधि द्वारा आपरेशन (OIU)</p>
                <p>* सभी प्रकार की पेशाब की समस्या, लिंग अण्डकोष एवं सेक्स सम्बन्धी समस्याओं का इलाज</p>
                <p>* मूत्र का बार-बार आना, धार कमजोर होना, रात में बार-बार उठना इत्यादि समस्याओं का इलाज</p>
                <p>* महिलाओं में मूत्र सम्बन्धी समस्या का इलाज</p>


            </div>

            <Footer />

        </>
    )
}

export default Facility