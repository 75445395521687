import React from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const Container = () => {
    return (
        <>
            <br /><br />
            <center><h1>Welcome to Excel Urology Hospital </h1></center>
            <div className='contai' style={{ height: '100%' }}>
                <h2>About Us </h2>
                <h1>
                    From Simple Urology & Andrology  To Complex Surgery </h1>


                <p>Dr Ravi Pandey
                    MBBS GSVM Kanpur
                    MS Surgery  GSVM Kanpur
                    Mch Urology and kidney transplant from SMS Jaipur</p>
                <p>Consultant Urologist at Excel Urology Hospital Dasharabag barabanki</p>
                <p>Consultant Urologist at panzea superspeciality Hospital Barabanki</p>
                <p>Consultant Urologisy at Goel super Speciality Hospital Lucknow</p>
                <p>Consultant Urology at KRM Hospital Lucknow</p>
                <p>Total work experience 9 years</p>
                <p>All kinds of operation facilities related to Urology is being done by me</p>




                <p>Established in the year 2021, Excel Urology care in  Barabanki is a top player in the category Urologist Doctors in the Barabanki. This well-known establishment acts as a one-stop destination servicing customers both local and from other parts of Barabanki</p>
                <p>Excel Urology care in Barabanki is one of the leading businesses in the Urologist Doctors. Also known for Urologist Doctors, Urology Hospitals, Urinary Tract Infection Treatment Doctors, Laser Prostate Gland Surgery Doctors, Hydrocele Doctors and much more. Find Address, Contact Number, Reviews & Ratings, Photos, Maps of Excel Urology care, Barabanki on Google.
                </p>
                <p>Urologist Doctors, also known as Urologists, are specially trained in treating problems and ailments related to the Urinary tract. They treat both men and women. They mainly focus on organs such as the Urinary bladder, Urethra and ureters. Urologists can also treat diseases pertaining to the male reproductive system.</p>


                <div className='links2'>

                    <Link to="/" style={{ textDecoration: 'none ' }}>
                        <a href='Tel:9795749576'>  <Button variant="success">Call Us </Button></a>
                    </Link>

                    <Link to="/Contact" style={{ textDecoration: 'none ' }}>
                        <Button variant="warning">Contact</Button>{' '}
                    </Link>

                    <Link to="/Appointment" style={{ textDecoration: 'none ' }}>
                        <Button variant="danger">Appointment</Button>{' '}
                    </Link>
                </div>

                <br />
            </div>

        </>
    )
}

export default Container