import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../Img/banner.jpg';
import slide2 from '../Img/banner2.jpg';
import slide3 from '../Img/banner3.jpg';
import slide4 from '../Img/wbanner2.jpg';

const Header = () => {
  return (
    <div className='header'>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slide1}
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h3>Excel Urology Hospital </h3>
            <p>Near Satokhar Talab Dashaharabag Haidergarh Barabanki</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slide2}
            alt="Second slide"
          />
          {/* <Carousel.Caption>
          <h3>Excel Urology Hospital </h3>
            <p>Near Satokhar Talab Dashaharabag Haidergarh Barabanki</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slide3}
            alt="Third slide"
          />
          {/* <Carousel.Caption>
          <h3>Excel Urology Hospital </h3>
           <p>Near Satokhar Talab Dashaharabag Haidergarh Barabanki</p>
          </Carousel.Caption> */}
        </Carousel.Item>


        <Carousel.Item>
          <img
            className="d-block w-100"
            src={slide4}
            alt="Third slide"
          />
          {/* <Carousel.Caption>
          <h3>Excel Urology Hospital </h3>
           <p>Near Satokhar Talab Dashaharabag Haidergarh Barabanki</p>
          </Carousel.Caption> */}
        </Carousel.Item>


      </Carousel>
    </div>
  );
};

export default Header;
