import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';
import About from './Pages/About';
import Contact from './Pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from './Components/Head';
import Gallery from './Pages/Gallery';
import Appointment from './Pages/Appointment';
import Doctorfile from './Pages/Doctorfile';

import Facility from './Pages/Facility';


function App() {
  return (

    <div className='main'>

      <BrowserRouter basename='' >
        <Head />
        <Navbar />
        <Routes>
          <Route path="*" element={<Layout />} />
          <Route path="/About" element={<About />} />
          <Route path="/Doctorfile" element={<Doctorfile />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Facility" element={<Facility />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Appointment" element={<Appointment />} />
        </Routes>

      </BrowserRouter>
    </div>


  );
}

export default App;
