import React from 'react'
import Footer from '../Components/Footer'
import  Container  from '../Components/Container'

const About = () => {
  return (
    <>

      <Container />

      <Footer />

    </>
  )
}

export default About