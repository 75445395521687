import React from 'react'
import Footer from '../Components/Footer'

import gal1 from '../Img/gal1.jpg'
import gal2 from '../Img/gal2.jpg'
import gal3 from '../Img/gal3.jpg'
import gal4 from '../Img/gal4.jpg'

import gal5 from '../Img/gal5.jpg'
import gal6 from '../Img/gal6.jpg'
import gal7 from '../Img/gal7.jpg'
import gal8 from '../Img/gal8.jpg'


import gal9 from '../Img/gal9.jpg'
import gal10 from '../Img/gal10.jpg'
import gal11 from '../Img/gal11.jpg'
import gal12 from '../Img/gal12.jpg'


import gal13 from '../Img/gal13.jpg'
import gal14 from '../Img/gal14.jpg'
import gal15 from '../Img/gal15.jpg'
import gal16 from '../Img/al10.jpg'


const Gallery = () => {
  return (
    <>
      <center><h1>Gallery</h1></center>

      <div className='gallery'>
        <div className='gallery2'>
          <img src={gal1} />
        </div>
        <div className='gallery2'>
          <img src={gal2} />
        </div>
        <div className='gallery2'>
          <img src={gal3} />
        </div>
        <div className='gallery2'>
          <img src={gal4} />
        </div>
      </div>


      <div className='gallery'>
        <div className='gallery2'>
          <img src={gal5} />
        </div>
        <div className='gallery2'>
          <img src={gal6} />
        </div>
        <div className='gallery2'>
          <img src={gal7} />
        </div>
        <div className='gallery2'>
          <img src={gal8} />
        </div>
      </div>

      <div className='gallery'>
        <div className='gallery2'>
          <img src={gal9} />
        </div>
        <div className='gallery2'>
          <img src={gal10} />
        </div>
        <div className='gallery2'>
          <img src={gal11} />
        </div>
        <div className='gallery2'>
          <img src={gal12} />
        </div>
      </div>


      <div className='gallery'>
        <div className='gallery2'>
          <img src={gal13} />
        </div>
        <div className='gallery2'>
          <img src={gal14} />
        </div>
        <div className='gallery2'>
          <img src={gal15} />
        </div>
        <div className='gallery2'>
          <img src={gal16} />
        </div>
      </div>

      <Footer />

    </>
  )
}

export default Gallery