import React from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';

import { Link } from 'react-router-dom';

import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

export default function App() {
  return (

    <div className='footer'>



      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block'>
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href='' className='me-4 text-reset'>
              <i class="fa fa-facebook-f"></i>
            </a>
            <a href='' className='me-4 text-reset'>
              <i class="fa fa-twitter"></i>
            </a>
            <a href='' className='me-4 text-reset'>
              <i class="fa fa-google"></i>
            </a>
            <a href='' className='me-4 text-reset'>
              <i class="fa fa-instagram"></i>
            </a>

          </div>
        </section>

        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <MDBIcon icon="gem" className="me-3" />
                  Excel Urology Hospital
                </h6>
                <p>
                Urologist Doctors, also known as Urologists, are specially trained in treating problems and ailments related to the urinary tract. They treat both men and women. They mainly focus on organs such as the urinary bladder, urethra and ureters. Urologists can also treat diseases pertaining to the male reproductive system.

                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Treatments</h6>
                <p>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                    Kidney Stone
                  </Link>
                </p>
                <p>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                    Ureteric Stone
                  </Link>
                </p>
                <p>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                    Prostate Enlargement
                  </Link>
                </p>
                <p>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>

                    Urinary Tract Infection
                  </Link>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                <p>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                    Home
                  </Link>
                </p>
                <p>
                  <Link to="/About" style={{ textDecoration: 'none', color: 'black' }}>
                    About
                  </Link>
                </p>
                <p>
                  <Link to="/Contact" style={{ textDecoration: 'none', color: 'black' }}>
                    Contact
                  </Link>
                </p>
                <p>
                  <Link to="/Appointment" style={{ textDecoration: 'none', color: 'black' }}>
                    Appointment
                  </Link>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>

                  <i class="fa fa-map-marker"></i>  Excel Urology Hospital, Dasharabag near Satokhar Talab Barabanki.
                </p>
                <p>


                  <i class="fa fa-envelope"></i>   pandeyravi485@gmail.com
                </p>
                <p>
                  <i class="fa fa-phone"></i> +91  9795749576
                </p>
                <p>
                  <i class="fa fa-phone"></i>   +91     9664313251
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2024 Copyright: Reserved  Developed Designed By
          <a className='text-reset fw-bold' href=''>
            Creative Digital World
          </a>
        </div>
      </MDBFooter>

    </div>
  );
}