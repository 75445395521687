import React from 'react';
import Footer from '../Components/Footer';

const Appointment = () => {
  return (
    <>
    <br/>
      <div className='appointment'>
      <center>  <h2>Book an Appointment</h2></center>
        <form>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email Address:</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="contactNo">Contact Number:</label>
          <input type="tel" id="contactNo" name="contactNo" required />

          <label htmlFor="address">Address:</label>
          <textarea id="address" name="address" rows="3" required></textarea>

          <label htmlFor="time">Preferred Time:</label>
          <input type="time" id="time" name="time" required />

          <label htmlFor="date">Preferred Date:</label>
          <input type="date" id="date" name="date" required />

          <button type="submit">Book Appointment</button>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Appointment;
