import React from 'react'

import Footer from '../Components/Footer'

const Doctorfile = () => {
  return (
    <>
    
    <div>Doctor Profile </div>

    <Footer/>
    
    </>
  )
}

export default Doctorfile