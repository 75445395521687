import React from 'react'
import kidni from '../Img/kidney.jpg'
import kidn2 from '../Img/blader.png'
import kidn3 from '../Img/protest.jpg'
import kidn4 from '../Img/urinal.jpg'
import kidn5 from '../Img/urintrack.jpg'
import kidn6 from '../Img/dylasis.jpg'

const Department = () => {
    return (
        <>
            <br /><br />

            <center><h1>Treatments</h1></center>
            <div className='depart'>

                <div className='depart2'>
                    <img src={kidni} />
                    <h1>Kidney Opration </h1>
                    <p>Nephrectomy (nuh-FREK-tuh-me) is a surgery to remove all or part of a kidney. Most often, it's done to treat kidney cancer or to remove a tumor that isn't cancerous. The doctor who does the surgery is called a urologic surgeon</p>
                </div>

                <div className='depart2'>
                    <img src={kidn2} />
                    <h1>Bladder Stones</h1>
                    <p>It may be possible to flush small bladder stones out of your bladder by drinking lots of water, but this may not work if you can't completely empty your bladder of urine.</p>
                </div>

                <div className='depart2'>
                    <img src={kidn3} />
                    <h1>Prostate </h1>
                    <p>Your prostate is a small, walnut-shaped organ. It's below your bladder and in front of your rectum. Its primary functions are to create fluids in your semen and force semen through your urethra when you ejaculate.</p>
                </div>

            </div>

            <div className='depart'>

                <div className='depart2'>
                    <img src={kidn4} />
                    <h1>Urinary infection </h1>
                    <p>UTIs are common infections that happen when bacteria, often from the skin or rectum, enter the urethra, and infect the urinary tract</p>
                </div>

                <div className='depart2'>
                    <img src={kidn5} />
                    <h1>Narrow urinary tract</h1>
                    <p>A urethral (u-REE-thrul) stricture involves scarring that narrows the tube that carries urine out of the body, called the urethra. </p>
                </div>

                <div className='depart2'>
                    <img src={kidn6} />
                    <h1>Dialysis  </h1>
                    <p>Dialysis is a procedure to remove waste products and excess fluid from the blood when the kidneys stop working properly. It often involves diverting blood to a machine to be cleaned.</p>
                </div>

            </div>


        </>
    )
}

export default Department