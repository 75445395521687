import React from 'react';
import Footer from '../Components/Footer';

const Contact = () => {
  return (
    <>
      <div className='contact'>
        <h2>Contact Us</h2>
        <form>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email Address:</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="contactNo">Contact Number:</label>
          <input type="tel" id="contactNo" name="contactNo" required />

          <button type="submit">Submit</button>
        </form>
        <br/>

        {/* Embed Google Maps */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14229.998538781874!2d81.1970555!3d26.9193707!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3999614ee84855f5%3A0x97f9b69c85095a38!2sEXCEL%20UROLOGY%20HOSPITAL%20Dr.%20RAVI%20PANDEY(Mch%20urology)!5e0!3m2!1sen!2sin!4v1709279610819!5m2!1sen!2sin"  style={{width:'100%', height:'450px'}} referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
