import React from 'react'

const Head = () => {
    return (
        <div className='head'>

            <div className='head2'>
                <p style={{fontSize:'12px'}}>Excel Urology Hospital, Dasharabag near Satokhar Talab Barabanki  </p>
            </div>


            <div className='head2'>
                <p> +91 9795749576  9664313251</p>
            </div>


            <div className='head2'>
                <p>pandeyravi485@gmail.com</p>
            </div>


        </div>
    )
}

export default Head