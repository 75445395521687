import React from 'react'
import Header from './Header'
import Container from './Container'
import Fration from './Fration'
import Footer from './Footer'
import Department from './Department'

const Layout = () => {
  return (
    <>
     <div className='main'>
     <Header />
      <Container />
      <Fration />
      <Department/>
      <Footer />

     </div>
    
    </>
  )
}

export default Layout