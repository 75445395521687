import React from 'react'
import Poster from '../Img/wbanner.jpg'
import Poster2 from '../Img/poster2.jpg'
import Poster3 from '../Img/al10.jpg'
import Poster4 from '../Img/dialysis.png'


const Fration = () => {
    return (
        <>

            <br />
            <center><h1> Our Surgical Blogs  </h1></center>
            <div className='fract'>

                <div className='fract2'>
                    <img src={Poster} />
                </div>
                <div className='fract2'>
                    <div class="embed-responsive embed-responsive-16by9">
                    <img src={Poster4} style={{hight:'100%'}} />
                        {/* <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HK-bW-I0EIU?si=Z0IH3MIVSiV5f-ES" style={{ width: '100%', height: '400px', borderRadius: '10px' }} allowfullscreen></iframe> */}
                    </div>
                </div>

            </div>


            <div className='fract'>

                <div className='fract2'>
                    <img src={Poster2} />
                </div>
                <div className='fract2'>
                    <img src={Poster3} />
                </div>

            </div>




        </>
    )
}

export default Fration
